import React, { useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getWhiteLogoApp } from "src/utils/app-logo"
import ExpiredTimeView from "./expited-time"
import { getPaymentToken, gatewayCharge } from "src/services/payment-service"
import OnlinePayment from "./online-payment"
import CountDownView from "src/components/count-down-view"

export const STATE_VIEW = {
  VALIDATE_PAGE: "VALIDATE_PAGE",
  SELECT_PAYMENT_TYPE: "SELECT_PAYMENT_TYPE",
  EXPIRED_TIME: "EXPIRED_TIME",
}

export default function OrderCart() {
  const [stateView, setStateView] = useState<string>(STATE_VIEW.VALIDATE_PAGE)
  const [paymentToken, setPaymentToken] = useState<string>("")
  const [failCallback, setFailCallback] = useState<string>("")
  const [searchParams] = useSearchParams()
  const [expiredTime, setExpiredTime] = useState<number>(0)
  const navigator = useNavigate()
  const [isHaveStripeSession, setIsHaveStripeSession] = useState<string>("")
  const [isHaveAppOtaSession, setIsHaveAppOtaSession] = useState<string>("")
  const [isClick, setIsClick] = useState<boolean>(false)

  const validatePage = () => {
    const paymentTokenValue = searchParams.get("paymentToken")
    if (!paymentTokenValue) {
      navigator("/")
    } else {
      setPaymentToken(paymentTokenValue)
    }
  }
  useEffect(() => {
    if (paymentToken && stateView === STATE_VIEW.VALIDATE_PAGE) {
      getPaymentTokenDetail()
    }
  }, [paymentToken, stateView])

  // get data init
  useEffect(() => {
    validatePage()
  }, [])

  const gotoHome = () => {
    const homeUrl = process.env.REACT_APP_HOME_DOMAIN_URL
    window.open(homeUrl, "_self")
  }

  const getPaymentTokenDetail = () => {
    getPaymentToken(paymentToken)
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data) {
          setFailCallback(res.data.data?.fail_callback || "")
          if (res.data.data?.expired) {
            setStateView(STATE_VIEW.EXPIRED_TIME)
          } else {
            // is Select view
            const isSelectPaymentType = searchParams.get("isSelect")
            if (isSelectPaymentType) {
              setExpiredTime((res.data.data?.expired_at || 0) * 1000)
              setIsHaveAppOtaSession(res.data.data?.redirect_url || "")
              setIsHaveStripeSession(res.data.data?.client_secret || "")
              setStateView(STATE_VIEW.SELECT_PAYMENT_TYPE)
            } else {
              if (res.data.data?.redirect_url) {
                navigator(`/ota-checkout?token=${paymentToken}`)
              } else {
                createPaymentSession("APP_OTA")
              }
            }
          }
        } else {
          navigator("/")
        }
      })
      .catch(() => {
        navigator("/")
      })
  }

  // Create payment session by type
  const createPaymentSession = (type: string) => {
    gatewayCharge(paymentToken, type)
      .then((res) => {
        if (type === "STRIPE") {
          if (res?.data?.data?.client_secret) {
            navigator(
              `/card-checkout?clientSecret=${res.data.data.client_secret}&token=${paymentToken}`
            )
          } else setIsClick(false)
        } else if (type === "APP_OTA") {
          if (res?.data?.data) {
            navigator(`/ota-checkout?token=${paymentToken}`)
          } else setIsClick(false)
        } else setIsClick(false)
      })
      .catch(() => setIsClick(false))
  }
  // handle select payment type
  const handleSelectPayment = (type: string) => {
    setIsClick(true)
    if (type === "STRIPE") {
      if (isHaveStripeSession) {
        navigator(
          `/card-checkout?clientSecret=${isHaveStripeSession}&token=${paymentToken}`
        )
      } else createPaymentSession("STRIPE")
    } else {
      if (isHaveAppOtaSession) navigator(`/ota-checkout?token=${paymentToken}`)
      else createPaymentSession("APP_OTA")
    }
  }

  if (stateView === STATE_VIEW.EXPIRED_TIME)
    return <ExpiredTimeView homeUrl={failCallback} />

  if (stateView === STATE_VIEW.SELECT_PAYMENT_TYPE && paymentToken)
    return (
      <div className="flex h-full flex-col">
        <div className=" h-[52px] bg-[linear-gradient(99.24deg,#1879FF_12.47%,#0658CC_98.43%)] md:h-[72px]">
          <div className=" mx-auto flex h-full max-w-[1200px] items-center items-center justify-between px-[24px] md:px-[0px]">
            <div
              className="flex cursor-pointer items-center"
              onClick={gotoHome}
            >
              <img
                alt="appLogo"
                className="h-[20px] w-[20px] md:h-[38px] md:w-[38px]"
                src={getWhiteLogoApp()}
              />
              <span className="ml-[10px] text-[20px] font-bold leading-[24px] text-white md:text-[32px] md:leading-[38px]">
                {process.env.REACT_APP_TITLE}
              </span>
            </div>
            {expiredTime ? (
              <CountDownView
                isHiddenUI={true}
                tartGetDate={expiredTime}
                handlePaymentFail={() => window.open(failCallback, "_self")}
              />
            ) : null}
          </div>
        </div>
        <div className="mx-auto my-4 flex w-full max-w-[588px] flex-col px-[24px]">
          <OnlinePayment
            updatePaymentType={(value: string) => handleSelectPayment(value)}
            isClick={isClick}
          />
        </div>
      </div>
    )

  return null
}
