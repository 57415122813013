import React from "react"
import "./main-layout.scss"
import type { MainLayoutProps } from "./main-layout-props"
import FooterView from "../footer-view"
import { useLocation } from "react-router-dom"
export type { MainLayoutProps }

export default function MainLayout(props: MainLayoutProps) {
  const location = useLocation()
  return (
    <div
      className="no-scrollbar mx-auto flex min-h-screen w-screen flex-col overflow-x-hidden overflow-y-scroll md:overflow-auto"
      id="content-body"
    >
      <main className="relative flex flex-1 w-full flex-col gap-1 bg-gray-50">
        {props.children}
      </main>
      {location.pathname !== "/ota-checkout" ? <FooterView/> : null}      
    </div>
  )
}
