import React, { useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import * as stripe from "@stripe/stripe-js"
import { STATE_VIEW } from "./props"
import { getLogoApp } from "src/utils/app-logo"
import {getPaymentToken, gatewayCharge, getPublicKey} from "src/services/payment-service"
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js"
import CountDownView from "src/components/count-down-view"
import { handlePromiseAll } from "src/utils/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import appOtaLogo from "src/assets/images/payment-method-app-ota.svg"

export const LoadingView = () => {
  return (
    <div className="flex h-full w-full items-center justify-center bg-white opacity-50">
      <div className="pi pi-spinner animate-spin text-[30px]" />
    </div>
  )
}

export interface PaymentDetailType  {
  failCallback: string
  expiredTime: number
}
export default function CardCheckout() {
  const [stateView, setStateView] = useState<string>(STATE_VIEW.INIT)
  const [paymentToken, setPaymentToken] = useState<string>("")
  const [searchParams] = useSearchParams()
  const navigator = useNavigate()
  const [clientSession, setClientSession] = useState<string>("")
  const [publickey, setPublicKey] = useState<string>("")
  const [paymentDetail, setPaymentDetail] = useState<PaymentDetailType | undefined> (undefined)
  const [isRender, setIsRender] = useState<boolean>(false)
  const {t} = useTranslation()

  const getPublicKeyData = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return getPublicKey().then((res:any) => {
      if(res?.data?.data) {
        setPublicKey(res.data.data)
      } else navigator("/")
    }).catch(() => navigator("/"))
  }
  const getPaymentTokenDetail = (token: string) => {
    return getPaymentToken(token)
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (!res?.data?.data?.expired) {
          setPaymentDetail({
            failCallback: res.data.data?.fail_callback || "",
            expiredTime: (res.data.data?.expired_at || 0) * 1000,
          })
        } else navigator("/")
      })
      .catch(() => {
        console.log("error")
      })
  }

  // Create payment session by type
  const createPaymentSession = () => {
    gatewayCharge(paymentToken, "APP_OTA")
      .then((res) => {
        if (res?.data?.data) {
          navigator(`/ota-checkout?token=${paymentToken}`)
        }
      })
  }
  
    
  const validatePage = () => {
    const checkoutSession = searchParams.get("clientSecret")
    const token = searchParams.get("token")
    if (!checkoutSession || !token) {
      navigator("/")
      return
    } else {
      setPaymentToken(token)
      setClientSession(checkoutSession)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const apis = [] as any[]
      apis.push(getPublicKeyData())
      apis.push(getPaymentTokenDetail(token))
      handlePromiseAll(apis).then(() => {
        setIsRender(true)
      })      
    }
  }

  useEffect(() => {
    if (clientSession && publickey && isRender) {
      if(paymentDetail) setStateView(STATE_VIEW.RENDER)
    }
  }, [clientSession, publickey, isRender, paymentDetail])

  const gotoHome = () => {
    const homeUrl = process.env.REACT_APP_HOME_DOMAIN_URL
    window.open(homeUrl, "_self")
  }
  // get data init
  useEffect(() => {
    validatePage()
  }, [])
  return (
    <div className="flex flex-1 relative">
      <div className={`absolute top-[0px] left-[0px] bg-white w-full h-full ${stateView !== STATE_VIEW.RENDER ? "z-[2]" : "hidden"}`}>
        <LoadingView />
      </div>
      <div className={`flex flex-col w-full h-full bg-white ${stateView === STATE_VIEW.RENDER ? "z-[2]" : "z-[0]"}`}>
        <div className="h-[52px] md:h-[72px] pb-[100px] md:pb-0 pt-[15px] md:pt-0">
          <div className="mx-auto flex flex-col md:flex-row h-full max-w-[950px] px-[12px] md:px-[24px] md:px-[0px] items-start md:items-center justify-between">
            <div
              className="flex cursor-pointer items-center"
              onClick={gotoHome}
            >
              <img
                alt="appLogo"
                className="h-[20px] w-[20px] md:h-[38px] md:w-[38px]"
                src={getLogoApp()}
              />
              <span className="ml-[10px] text-[20px] font-bold leading-[24px] text-[#1570EF] md:text-[32px] md:leading-[38px]">
                {process.env.REACT_APP_TITLE}
              </span>
            </div>
            <div onClick={createPaymentSession} className="flex justify-between mt-[20px] md:mt-0 bg-[#1570EF] w-full md:w-auto items-center justify-center rounded-3 p-[12px] px-[20px] cursor-pointer">
              <span className="text-[14px] font-bold leading-[16px] text-white md:text-[20px] md:leading-[24px] mr-[10px]">{capitalizedFirst(t("payment-page.app-ota-gate"))}</span>

              <div className="flex flex-row">
                <img
                  alt="visaLogo"
                  className="h-[24px] w-[34px] md:h-[24px] md:w-[34px]"
                  src={appOtaLogo}
                />
              </div>
            </div>
            {
              paymentDetail ? <CountDownView isHiddenUI={true} tartGetDate={paymentDetail.expiredTime} handlePaymentFail={() => window.open(paymentDetail.failCallback, "_self")}/> : null
            }            
          </div>
        </div>
        <div className="mt-[24px]" id="checkOutForm">
          {
            clientSession && publickey && stateView === STATE_VIEW.RENDER ? (
              <EmbeddedCheckoutProvider
                stripe={stripe.loadStripe(publickey as string)}
                options={
                  {
                    clientSecret: clientSession,
                  }
                }
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            ) : null
          }

        </div>
      </div>
    </div>
  )
}