import React, { useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { PaymentDetailType, STATE_VIEW } from "./props"
import { getLogoApp } from "src/utils/app-logo"
import {getPaymentToken, gatewayCharge} from "src/services/payment-service"
import CountDownView from "src/components/count-down-view"
import { handlePromiseAll } from "src/utils/common"
import { LoadingView } from "../order-cart/loading-view"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import visaLogo from "src/assets/images/payment-method-visa.svg"
import masterCardLogo from "src/assets/images/payment-method-master-card.svg"
import moreLogo from "src/assets/images/payment-method-more.svg"
import FooterView from "src/components/footer-view"

export default function AppOtaCheckout() {
  const [stateView, setStateView] = useState<string>(STATE_VIEW.INIT)
  const [paymentToken, setPaymentToken] = useState<string>("")
  const [searchParams] = useSearchParams()
  const navigator = useNavigate()
  const [paymentDetail, setPaymentDetail] = useState<PaymentDetailType | undefined> (undefined)
  const [isRender, setIsRender] = useState<boolean>(false)
  const [isLoadIframe, setIsLoadIframe] = useState<boolean>(false)
  const {t} = useTranslation()

  const getPaymentTokenDetail = (token: string) => {
    return getPaymentToken(token)
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (!res?.data?.data?.expired) {
          setPaymentDetail({
            failCallback: res.data.data?.fail_callback || "",
            expiredTime: (res.data.data?.expired_at || 0) * 1000,
            redirectUrl: res.data.data?.redirect_url || "",
          })
        } else navigator("/")
      })
      .catch(() => {
        console.log("error")
      })
  }

  // Create payment session by type
  const createPaymentSession = () => {
    gatewayCharge(paymentToken, "STRIPE")
      .then((res) => {
        if (res?.data?.data) {
          navigator(
            `/card-checkout?clientSecret=${res.data.data.client_secret}&token=${paymentToken}`
          )
        }
      })
  }
  

  const validatePage = () => {
    const token = searchParams.get("token")
    if (!token) {
      navigator("/")
      return
    } else {
      setPaymentToken(token)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const apis = [] as any[]
      apis.push(getPaymentTokenDetail(token))
      handlePromiseAll(apis).then(() => {
        setIsRender(true)
      })      
    }
  }

  useEffect(() => {
    if (isRender) {
      if(paymentDetail) setStateView(STATE_VIEW.RENDER)
    }
  }, [isRender, paymentDetail])

  const gotoHome = () => {
    const homeUrl = process.env.REACT_APP_HOME_DOMAIN_URL
    window.open(homeUrl, "_self")
  }
  // // get data init
  useEffect(() => {
    validatePage()
  }, [])

  const handleOnLoad = () => {
    setIsLoadIframe(true)
  }
  const detectStatusSessionPayment = () => {
    const token = searchParams.get("token")
    return getPaymentToken(token || "")
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.status === "succeeded" || res?.data?.data?.status ==="waiting_for_account") {
          const successCallback = res?.data?.data?.success_callback
          window.open(successCallback, "_self")
        } else if(res?.data?.data?.status === "payment_failed" || res?.data?.data?.status ==="token_expired") {
          const failCallback = res?.data?.data?.success_callback
          window.open(failCallback, "_self")
        }
      })
      .catch(() => {
        console.log("error")
      })
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if(isLoadIframe) {
        detectStatusSessionPayment()
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [isLoadIframe])
  return (
    <div className="h-[110vh] md:h-[auto] w-full">
      <div className={`bg-white w-full h-full absolute top-[0px] left-[0px] ${stateView !== STATE_VIEW.RENDER ? "z-[2]" : "z-[0]"}`}>
        <LoadingView />
      </div>
      <div className={`flex flex-col absolute w-full h-full bg-white top-[0px] left-[0px] ${stateView === STATE_VIEW.RENDER ? "z-[2]" : "z-[0]"}`}>
        <div className="h-[52px] md:h-[72px] pb-[100px] md:pb-0 pt-[15px] md:pt-0">
          <div className="mx-auto flex flex-col md:flex-row h-full max-w-[1010px] px-[12px] md:px-[24px] md:px-[0px] items-start md:items-center justify-between">
            <div
              className="flex cursor-pointer items-center"
              onClick={gotoHome}
            >
              <img
                alt="appLogo"
                className="h-[20px] w-[20px] md:h-[38px] md:w-[38px]"
                src={getLogoApp()}
              />
              <span className="ml-[10px] text-[20px] font-bold leading-[24px] text-[#1570EF] md:text-[32px] md:leading-[38px]">
                {process.env.REACT_APP_TITLE}
              </span>
            </div>
            <div onClick={createPaymentSession} className="flex justify-between mt-[20px] md:mt-0 bg-[#1570EF] w-full md:w-auto items-center justify-center rounded-3 p-[12px] px-[20px] cursor-pointer">
   
              <span className="text-[14px] font-bold leading-[16px] text-white md:text-[20px] md:leading-[24px] mr-[10px]">{capitalizedFirst(t("payment-page.back"))}</span>

              <div className="flex flex-row">
                <img
                  alt="visaLogo"
                  className="h-[24px] w-[34px] md:h-[24px] md:w-[34px]"
                  src={visaLogo}
                />
                <img
                  alt="masterCardLogo"
                  className="h-[24px] w-[34px] md:h-[24px] md:w-[34px] ml-[5px] mr-[5px]"
                  src={masterCardLogo}
                />
                <img
                  alt="moreLogo"
                  className="h-[24px] w-[24px] md:h-[24px] md:w-[34px]"
                  src={moreLogo}
                />
              </div>
            </div>
            {
              paymentDetail ? <CountDownView isHiddenUI={true} tartGetDate={paymentDetail.expiredTime} handlePaymentFail={() => window.open(paymentDetail.failCallback, "_self")}/> : null
            }            
          </div>
        </div>
        <div className="flex h-auto" id="otaCheckoutForm">
          <iframe
            width={"100%"}
            className="h-[85vh] md:h-[100vh-72px]"
            id="otaCheckout"
            title="otaCheckout"
            src={paymentDetail?.redirectUrl}
            onLoad={handleOnLoad}
          />
        </div>
        <FooterView/>
      </div>      
    </div>
  )
}